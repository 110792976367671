html,
body {
  height: 100%;
}

body {
  display: flex;
  flex-direction: column;
}

#root {
  height: 100%;
  display: flex;
  flex-direction: column;
}

#content {
  flex: 1 0 auto;
}

#footer {
  flex-shrink: 0;
}
